import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import parse from "html-react-parser"
import { Container } from "react-bootstrap"
import Section, { Row, Col } from "@ui/wrapper"
import Text from "@ui/text"
import Anchor from "@ui/anchor"
import Button from "@ui/button"
import Heading from "@ui/heading"
import Social, { SocialLink } from "@ui/social"
import { navigate } from "gatsby"
import { getImage } from "gatsby-plugin-image"
import { convertToBgImage } from "gbimage-bridge"
import {
  FooterWrap,
  FooterTop,
  FooterWidget,
  LogoWidget,
  TextWidget,
  FooterWidgetList,
  FooterBottom,
} from "./footer.style"

const Footer = ({ copyrightStyle, ...props }) => {
  const siteInfo = useStaticQuery(graphql`
    query FooterSiteQuery {
      site {
        siteMetadata {
          copyright
          contact {
            phone
            email
            address
            website
          }
          social {
            twitter
            github
            weibo
          }
        }
      }
      file(relativePath: { eq: "images/bg/cta-bg-2.png" }) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            placeholder: TRACED_SVG
            formats: WEBP
            quality: 100
          )
        }
      }
    }
`)

  const imageData = convertToBgImage(getImage(siteInfo.file))
  const { phone, email, address, website } = siteInfo.site.siteMetadata.contact
  const { copyright } = siteInfo.site.siteMetadata
  const { twitter, github, weibo } = siteInfo.site.siteMetadata.social
  function scrollToAnchor(anchorName) {
    if (anchorName) {
      let anchorElement = document.getElementById(anchorName)
      if (anchorElement) {
        anchorElement.scrollIntoView({ block: "start", behavior: "smooth" })
      } else {
        navigate("/")
          setTimeout(() => {
            const element = document.getElementById(anchorName)
            if (element) {
              element.scrollIntoView({ block: "start", behavior: "smooth" })
            }
          }, 1500)
      }
    }
  }

  return (
    <Section bgImage={imageData}>
    <FooterWrap {...props}>
      <FooterTop>
        <Container>
          <Row>
            <Col lg={4} sm={6}>
              <FooterWidget responsive={{ medium: { mb: "31px" } }}>
              <LogoWidget>
                  <StaticImage
                    src="../../../assets/images/logo/logo-long.png"
                    alt="logo"
                    width={446}
                    height={75}
                  />
                </LogoWidget>
                <TextWidget>
                  {/* {phone && <Text mb="10px"><Anchor path={`tel:${phone}`} fontWeight="800" color="#333" hoverstyle="2">{phone}</Anchor></Text>} */}
                  {/* {website && <Text mb="10px"><Anchor path={website} hoverstyle="2">{website}</Anchor></Text>} */}
                  <a href="javascript:location='tel:\u002b\u0036\u0031\u0028\u0033\u0029\u0020\u0039\u0032\u0035\u0031\u0020\u0037\u0034\u0033\u0034';void 0">Phone: +61(3) 9251 7434</a>
                  {email && (
                    <a href="javascript:location='mailto:\u0064\u0069\u0072\u0065\u0063\u0074\u006f\u0072\u0040\u0074\u0075\u006c\u0069\u0070\u002e\u0061\u0063\u0061\u0064\u0065\u006d\u0079';void 0">Email: director@tulip.academy</a>
                  )}
                  {address && <Text mb="10px">{address}</Text>}
                </TextWidget>
              </FooterWidget>
            </Col>
            <Col lg={2} md={4} sm={6}></Col>
            <Col lg={2} md={4} sm={6}>
              <FooterWidget responsive={{ medium: { mb: "31px" } }}>
                <Heading as="h6" mt="-3px" mb="20px" style={{marginTop:"10px", marginBottom:"5px"}}>
                  Navigate
                </Heading>
                <FooterWidgetList>
                  <li>
                    <Anchor path="/members" color="textColor" hoverstyle="2">
                      Members
                    </Anchor>
                  </li>
                  <li>
                    <Anchor path="/research" color="textColor" hoverstyle="2">
                      Our Research
                    </Anchor>
                  </li>
                  <li>
                    <Anchor path="/open-data" color="textColor" hoverstyle="2">
                      Open source
                    </Anchor>
                  </li>
                  <li>
                    <Anchor path="/blog" color="textColor" hoverstyle="2">
                      Blog
                    </Anchor>
                  </li>
                </FooterWidgetList>
              </FooterWidget>
            </Col>
            <Col lg={2} md={4} sm={6}>
              <FooterWidget responsive={{ medium: { mb: "27px" } }}>
                <Heading as="h6" mt="-3px" mb="20px" style={{marginTop:"10px", marginBottom:"5px"}}>
                  Quick links
                </Heading>
                <FooterWidgetList>
                  <li>
                    <Anchor
                      onClick={() => scrollToAnchor("news")}
                      color="textColor"
                      hoverstyle="2"
                    >
                      News & Events
                    </Anchor>
                  </li>
                  <li>
                    <Anchor
                      onClick={() => scrollToAnchor("about")}
                      color="textColor"
                      hoverstyle="2"
                    >
                      About Us
                    </Anchor>
                  </li>
                  <li>
                    <Anchor path="/contact-us" color="textColor" hoverstyle="2">
                      Contact Us
                    </Anchor>
                  </li>
                  <li>
                    <Anchor
                      path="https://www.deakin.edu.au/research/research-degrees-and-PhD/research-applications"
                      color="textColor"
                      hoverstyle="2"
                      target="_blank"
                    >
                      Join Us
                    </Anchor>
                  </li>
                </FooterWidgetList>
              </FooterWidget>
            </Col>
            <Col lg={2} md={4} sm={6}>
              <FooterWidget>
                <Heading as="h6" mt="-3px" mb="20px" style={{marginTop:"10px", marginBottom:"5px"}}>
                  Publication
                </Heading>
                <FooterWidgetList>
                  <li>
                    <Anchor
                      path="/publication/ai"
                      color="textColor"
                      hoverstyle="2"
                    >
                      Artificial Intelligence{" "}
                    </Anchor>
                  </li>
                  <li>
                    <Anchor
                      path="/publication/bi"
                      color="textColor"
                      hoverstyle="2"
                    >
                      Business Intelligence
                    </Anchor>
                  </li>
                  <li>
                    <Anchor
                      path="/publication/ps"
                      color="textColor"
                      hoverstyle="2"
                    >
                      Privacy & Security
                    </Anchor>
                  </li>
                  <li>
                    <Anchor
                      path="/publication/app"
                      color="textColor"
                      hoverstyle="2"
                    >
                      Applications
                    </Anchor>
                  </li>
                </FooterWidgetList>
              </FooterWidget>
            </Col>
            {/* <Col lg={2} md={4} sm={6}>
							<FooterWidget mt='50px' responsive={{ small: { mt: '34px' } }}>
								<FooterWidgetList>
									<li>
										<Button path="/" to="/" imgbutton="true" shadow="true">
											<StaticImage src="../../../assets//images/icons/aeroland-button-google-play.jpg" alt="Google Play" width={162} height={48} />
										</Button>
									</li>
									<li>
										<Button path="/" to="/" imgbutton="true" shadow="true">
											<StaticImage src="../../../assets//images/icons/aeroland-button-app-store.jpg" alt="App Store" width={162} height={48} />
										</Button>
									</li>
								</FooterWidgetList>
							</FooterWidget>
						</Col> */}
          </Row>
        </Container>
      </FooterTop>
      <FooterBottom>
        <Container>
          <Row className="align-items-center">
            <Col md={6} className="text-center text-md-left">
              {copyright && (
                <Text {...copyrightStyle}>
                  &copy; 2006 - {new Date().getFullYear()} {parse(copyright)}
                </Text>
              )}
            </Col>
            <Col md={6} className="text-center text-md-right">
              <Social
                space="8px"
                tooltip={true}
                shape="rounded"
                varient="outlined"
              >
                {github && (
                  <SocialLink path={github} title="Github">
                    <i className="fab fa-github link-icon"></i>
                  </SocialLink>
                )}
                {twitter && (
                  <SocialLink path={twitter} title="Twitter">
                    <i className="fab fa-twitter link-icon"></i>
                  </SocialLink>
                )}
                {weibo && (
                  <SocialLink path={weibo} title="Weibo">
                    <i className="fab fa-weibo link-icon"></i>
                  </SocialLink>
                )}
              </Social>
            </Col>
          </Row>
        </Container>
      </FooterBottom>
    </FooterWrap>
    </Section>
  )
}

Footer.propTypes = {
  bgcolor: PropTypes.string,
  reveal: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

Footer.defaultProps = {
  reveal: "false",
  copyrightStyle: {
    responsive: {
      small: {
        pb: "15px",
      },
    },
  },
}

export default Footer
